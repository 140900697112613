import React from 'react';
import "./About.css";
import HaveQuestion from '../Subcomponents/HaveQuestion';
import Headdemo from '../Subcomponents/Headdemo';
import Footer from '../Subcomponents/HeaderComponents/Footer';
import Phone from '../Subcomponents/Phone';
// import alias from "../../assets/images/ilias.mp4";
import ConditionComp from '../Subcomponents/ConditionComp/ConditionComp';
// import teamLogo from "../../assets/images/team.png";
import documentaryLogo from "../../assets/images/documentary.png";
// import QuotesPage from '../Subcomponents/QuotesPage/QuotesPage';
import aboutLogo from "../../assets/images/about-page-logo.png";
import NewsPageCompBtn from '../Subcomponents/NewsPageComp/NewsPageCompBtn';
import AboutTeam from '../Subcomponents/AboutTeam/AboutTeam';
import { useTranslation } from 'react-i18next';
const About = () => {
    const { t } = useTranslation();
    // const [isMuted, setIsMuted] = useState(true);
    // const videoRef = useRef(null);
    // const toggleMute = () => {
    //     if (videoRef.current) {
    //         videoRef.current.muted = !isMuted;
    //         setIsMuted(!isMuted);
    //     }
    // };
    return (
        <>
            <ConditionComp />
            <Headdemo />
            <div className='about-main'>
                <div className='about2' data-aos="fade-down" data-aos-duration="3000">
                    <div className='about2-data-main'>
                        <div className='about2-data'>
                            <div className='about2-data-content1'>
                                <h4>
                                    {t("aboutUsBuildTitle")}
                                </h4>
                                <p>
                                    {t("aboutUsPara")}
                                </p>
                                <div className='about2-data-anchor'>
                                    <NewsPageCompBtn newsAnchorTitle={t("aboutUsCall")} newsAnchor="/contact" />
                                </div>
                            </div>
                            <div className='about2-data-content2'>
                                <div className='about2-data-content2-logo'>
                                    <img src={aboutLogo} alt='about-logo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='aboutslider'>
                    <ShariahQuoteSlider
                        shariahImage1={aboutpic}
                        shariahImage2={aboutpic1}
                    />
                </div> */}
                {/* <div className='about-quote'>
                    <QuotesPage
                        quoteTitle="‘’And Allah has permitted trade and forbidden ribba.’’"
                        quoteReference=" Surah al-Bakra,verse 278" />
                </div> */}
                <div className='about-team-data'>
                    <h4>
                        {t("teamTitle")}
                    </h4>
                    <h6>
                        {t("teamPara")}
                    </h6>
                    <div className='about-team-container'>
                        <AboutTeam />
                    </div>
                    <p>
                        {t("teamEmployees")}
                    </p>
                </div>
                <div className='about3'>
                    <div className='container'>
                        <div className='About-main'>
                            {/* <div className='About-video'>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <video ref={videoRef} autoPlay loop muted={isMuted} style={{ width: '100%' }}>
                                        <source src={alias} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        onClick={toggleMute}
                                        style={{
                                            position: 'absolute',
                                            bottom: '10px',
                                            left: '10px',
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            border: 'none',
                                            borderRadius: '50%',
                                            color: 'white',
                                            padding: '10px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {isMuted ? '🔇' : '🔊'}
                                    </button>
                                </div>
                            </div> */}
                            <div className='About-content'>
                                <h4>
                                    {t("aboutProsperTitle")}
                                </h4>
                                <p>
                                    {t("aboutProsperPara1")}
                                    {/* We strive to pave the way for a fresh wave of private investments and broaden the scope of high-return
                                    opportunities for more individuals and entities. We're dedicated to creating a thriving investor community
                                    that's actively involved and invested in supporting projects that push the world towards progress. */}
                                </p>
                                <p>
                                    {t("aboutProsperPara2")}
                                    {/* Our ultimate goal is to revolutionize the world of private investments, enabling more people to access
                                    lucrative opportunities and generate higher returns. Unlocking full potential and engaged investors! */}
                                </p>
                                <div className='about-ceo-content mb-4'>
                                    <p>
                                        {t("aboutCeoPara1")}
                                        {/* Our ultimate goal is to revolutionize the world of private investments, enabling more people to access
                                    lucrative opportunities and generate higher returns. Unlocking full potential and engaged investors! */}
                                    </p>
                                    <p>
                                        {t("aboutCeoPara2")}
                                    </p>
                                    <div className='about-documentary'>
                                        <div className='about-doc-logo'>
                                            <img src={documentaryLogo} alt='document-logo' />
                                        </div>
                                        <h4 className='my-auto'>
                                            <p>
                                                <a href="https://www.filmvandaag.nl/serie/9467-snelle-jongens-shortcut-naar-succes/online-kijken#card-videoland" target='blank' >
                                                    {t("aboutCeoDocumentaryLink")}
                                                </a>
                                            </p>
                                        </h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about5'>
                            <HaveQuestion />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Phone />
        </>
    )
}
export default About;