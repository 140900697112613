import React from 'react';
import javed from "../../../assets/images/Bg remove/6.png";
import abdullah from "../../../assets/images/Bg remove/7.png";
import abdullahHover from "../../../assets/images/Bg remove/Muhammad-Abdullah.svg";
import javedHover from "../../../assets/images/Bg remove/Javed-Somroo.svg";
import "./AboutBoardMembers.css";
import { useTranslation } from 'react-i18next';

const AboutTecTeam = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-tech-main">
        <h4>{t("techTeamTitle")}</h4>
        <div className="about-board-tech1">
          <img src={javed} alt="mem-logo" className="image1" />
          <img src={javedHover} alt="mem-logo" className="image2" />
        </div>
        <div className="about-board-tech2">
          <img src={abdullah} alt="mem-logo" className="image1" />
          <img src={abdullahHover} alt="mem-logo" className="image2" />
        </div>
      </div>
    </>
  );
}

export default AboutTecTeam;