import React from "react";
import "./AboutBoardMembers.css";

const AboutLogoCard = (props) => {
  return (
    <div className="about-logo-main">
      <h4>{props.aboutLogoTitle}</h4>
      <div className="about-logo-sec">
        <img src={props.LogoAboutSec} alt="logo-sec" />
      </div>
    </div>
  );
};

export default AboutLogoCard;
