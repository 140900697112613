import React from "react";
import "./HeaderLogo.css";

const HeaderLogo = (props) => {
  return (
    <div className="header-logo-main">
      <img src={props.HeaderLogo} alt="header-logo" />
    </div>
  );
};

export default HeaderLogo;
