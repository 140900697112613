import React from 'react'
import NumberMain from './NumberMain';
import PowerMain from './PowerMain';
import "./Track.css";
import { useTranslation } from 'react-i18next';
const TrackMain = () => {
  const { t } = useTranslation();
  return (
    <div className='track-main bg-white'>
      <div className='container'>
        <div className='track-content text-center' ata-aos="zoom-out-up" data-aos-duration="3000">
          <div className='track-data'>
            <h4>
              {t("joinTitle")}
            </h4>
            <h5
              dangerouslySetInnerHTML={{
                __html: t("interestFreeSubTitle"),
              }}>
            </h5>
            <p
              dangerouslySetInnerHTML={{
                __html: t("interestFreePara"),
              }}>
            </p>
          </div>
        </div>
        <NumberMain />
      </div>
      <div className='track-power-bg'>
        <PowerMain />
      </div>
    </div>
  )
}

export default TrackMain;