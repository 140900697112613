import React from 'react';
import "./AboutBoardMembers.css";
import sale1 from "../../../assets/images/Bg remove/2.png";
// import sale2 from "../../../assets/images/Bg remove/3.png";
import sale3 from "../../../assets/images/Bg remove/4.png";
import sale4 from "../../../assets/images/Bg remove/5.png";
// import sale2Hover from "../../../assets/images/Bg remove/Sietze-Bruno.svg";
import sale1Hover from "../../../assets/images/Bg remove/armaan-ayaz.svg";
import sale4Hover from "../../../assets/images/Bg remove/Muhammad-Lemrini.svg";
import sale3Hover from "../../../assets/images/Bg remove/Awil-Adan.svg";
import AboutTecTeam from './AboutTecTeam';
import { useTranslation } from 'react-i18next';

const AboutSales = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-sales-sec">
        <div className="about-sales-main">
          <h4>{t("marketingTeamTitle")}</h4>
          <div className="about-board-member2">
            <img src={sale1} alt="mem-logo" className="image1" />
            <img src={sale1Hover} alt="mem=logo" className="image2" />
          </div>
          <div className="about-board-member3">
            <img src={sale3} alt="mem-logo" className="image1" />
            <img src={sale3Hover} alt="mem=logo" className="image2" />
          </div>
          {/* <div className="about-board-member4">
            <img src={sale2} alt="mem-logo" className="image1" />
            <img src={sale2Hover} alt="mem=logo" className="image2" />
          </div> */}
          <div className="about-board-member5">
            <img src={sale4} alt="mem-logo" className="image1" />
            <img src={sale4Hover} alt="mem=logo" className="image2" />
          </div>
        </div>
      </div>
      <AboutTecTeam />
    </>
  );
}

export default AboutSales