import React from "react";
import "./BloomCard.css";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);
const BloomCard = ({ bloomHead, bloomValue, bloomChangeOneDay, graphData }) => {
  const hasHyphen = bloomChangeOneDay.includes("-");
  const iconStyle = {
    color: "#5348CE",
  };
  const iconStyle1 = {
    color: "red",
    transform: "rotate(180deg)",
  };
  const data = {
    labels: graphData.map((_, index) => index),
    datasets: [
      {
        data: graphData,
        backgroundColor: hasHyphen
          ? "rgba(255, 0, 0, 0.3)"
          : "rgba(83, 72, 206, 0.6)",
        borderColor: hasHyphen ? "red" : "#5348CE",
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        beginAtZero: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };
  return (
    <div className="bloom-main ">
      <div className="bloom-content">
        <h4>{bloomHead}</h4>
        <p>{bloomValue}</p>
      </div>
      <div className="bloomchart">
        <Line data={data} options={options} />
        <p>
          {hasHyphen ? (
            <NavigationIcon style={iconStyle1} />
          ) : (
            <NavigationIcon style={iconStyle} />
          )}
          <span>{bloomChangeOneDay}</span>
        </p>
      </div>
    </div>
  );
};
export default BloomCard;
