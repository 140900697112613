import React from 'react';
import "./Expert.css";
import ArrowText from '../ArrowText';
import halfMoon from "../../../assets/images/Half-Moon-Icon.png";
import { useTranslation } from 'react-i18next';
const Expert = (props) => {
    const { t } = useTranslation();
    return (
        <div className='expert-sec'>
            <div className='container'>
                <div className='expert-content d-flex justify-content-between flex-wrap'>
                    <div className='expert1' data-aos="fade-up" data-aos-duration="3000">
                        <h3>
                            {props.expertHead}
                        </h3>
                    </div>
                    <div className='expert2' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <div className='expert-para-logo d-flex'>
                            <p>
                                {props.expertpara}
                            </p>
                            <div className='expertlogo'>
                                <img src={halfMoon} alt="moon-logo" />
                            </div>
                        </div>
                        <div className='expert-button'>
                            <ArrowText
                                linktext={t("learnTitle")}
                                headanchor="/principles" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Expert;
