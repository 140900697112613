import React from 'react';
// import estate1 from "../../../assets/images/Bg remove/8.png";
import estate2 from "../../../assets/images/Bg remove/9.png";
// import estate1Hover from "../../../assets/images/Bg remove/asos-salih.svg";
import estate2Hover from "../../../assets/images/Bg remove/Asos.svg";
import "./AboutBoardMembers.css";
import { useTranslation } from 'react-i18next';

const AboutRealEstateManager = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-estate-main">
        <h4>{t("realEstateTeamTitle")}</h4>
        {/* <div className="about-estate-manager1">
          <img src={estate1} alt="mem-logo" className="image1" />
          <img src={estate1Hover} alt="mem-logo" className="image2" />
        </div> */}
        <div className="about-estate-manager2">
          <img src={estate2} alt="mem-logo" className="image1" />
          <img src={estate2Hover} alt="mem-logo" className="image2" />
        </div>
      </div>
    </>
  );
}

export default AboutRealEstateManager