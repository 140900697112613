import React, { useEffect, useState } from "react";
import axios from "axios";
import BloomCard from "../Subcomponents/BloomCard/BloomCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import "./BloombergData.css";
const BloombergData = () => {
  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const options1 = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    smartSpeed: 3000,
    easing: "easeinout",
    responsive: {
      320: {
        items: 1,
      },
      500: {
        item: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 2.5,
      },
      992: {
        items: 3,
      },
      1166: {
        items: 2.8,
      },
      1170: {
        items: 4,
      },
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://tgcapi.com/tgc/growth/trending/"
        );
        setJsonData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="">
      {loading ? (
        <>
          <div className="bloom-loader text-center my-4">
            <div className="spinner-grow" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </>
      ) : (
        <div className="bloom-contain">
          <div className="bloomberg-sec">
            <div className="bloomberg-title-sec">
              <div className="bloomberg-title">
                <h3>Trending</h3>
              </div>
              </div>
              <div className="bloom-trading-carous">

            <OwlCarousel className="owl-theme-review" {...options1}>
              {jsonData.map((item, index) => {
                return (
                  <div key={index} className="bloomberg-main">
                    <BloomCard
                      bloomHead={item.market_name}
                      bloomValue={item.market_price}
                      bloomChangeOneDay={item.daily_change}
                      graphData={item.market_series}
                    />
                  </div>
                );
              })}
                </OwlCarousel>
                </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BloombergData;
