import React from "react";
import "./AboutTeam.css";
import AboutBoardMembers from "./AboutBoardMembers";
import AboutSales from "./AboutSales";
import AboutLogoCard from "./AboutLogoCard";
import gLogo from "../../../assets/images/Bg remove/g-logo.png";
import d1 from "../../../assets/images/Bg remove/10.png";
import shariah from "../../../assets/images/Bg remove/14 1.png";
import Ilas from "../../../assets/images/Bg remove/11.png";
import rehmat from "../../../assets/images/Bg remove/12.png";
import yuavz from "../../../assets/images/Bg remove/1.png";
import board1 from "../../../assets/images/Bg remove/13.png";
import sale1 from "../../../assets/images/Bg remove/2.png";
// import sale2 from "../../../assets/images/Bg remove/3.png";
import sale3 from "../../../assets/images/Bg remove/4.png";
import sale4 from "../../../assets/images/Bg remove/5.png";
import tech1 from "../../../assets/images/Bg remove/6.png";
import tech2 from "../../../assets/images/Bg remove/7.png";
// import estate1 from "../../../assets/images/Bg remove/8.png";
import estate2 from "../../../assets/images/Bg remove/9.png";
import daoudHover from "../../../assets/images/Bg remove/Daoud-Ahmidi.svg";
import purpleGLogo from "../../../assets/images/Bg remove/15 1.png";
import catLogo from "../../../assets/images/Bg remove/cat.svg";
import { useTranslation } from "react-i18next";

const AboutTeam = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-team-main">
        <div className="about-team-board-sec1">
          <AboutSales />
        </div>
        <div className="about-team-board-sec2">
          <AboutBoardMembers />
        </div>
        <div className="about-team-board-sec3">
          <div className="aboutTeam-other border-logo about-shariah-margin">
            <AboutLogoCard
              LogoAboutSec={shariah}
              aboutLogoTitle={t("advisoryTeamTitle")}
            />
          </div>
          <div className="aboutTeam-other about-venture-margin">
            <AboutLogoCard
              LogoAboutSec={gLogo}
              aboutLogoTitle={t("ventureCapitalTitle")}
            />
          </div>
          <div className="aboutTeam-other about-stock-margin">
            <AboutLogoCard
              LogoAboutSec={gLogo}
              aboutLogoTitle={t("stockManagerTitle")}
            />
          </div>
          <div className="aboutTeam-other about-crypto-margin">
            {/* <AboutLogoCard
              LogoAboutSec={d1}
              aboutLogoTitle="Crypto Fund Manager"
            /> */}
            <div className="about-logo-main">
              <h4>{t("cryptoManagerTitle")}</h4>
              <div className="about-logo-sec">
                <img src={d1} alt="logo-sec" className="image1" />
                <img src={daoudHover} alt="logo-sec" className="image2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-about-team-main">
        <div className="mobile-board">
          <h4>{t("boardTeamTitle")}</h4>
          <div className="mobile-board-main">
            <div className="mobile-board-sec1">
              <img src={purpleGLogo} alt="logo" />
            </div>
            <div className="mobile-board-sec2">
              <img src={rehmat} alt="logo" />
            </div>
            <div className="mobile-board-sec3">
              <img src={yuavz} alt="logo" />
            </div>
            <div className="mobile-board-sec4">
              <img src={board1} alt="logo" />
            </div>
            <div className="mobile-board-sec5">
              <img src={Ilas} alt="logo" />
            </div>
          </div>
        </div>
        <div className="mobile-marketing">
          <h4>{t("marketingTeamTitle")}</h4>
          <div className="mobile-marketing-main">
            {/* <div className="mobile-marketing-sec1">
              <img src={sale2} alt="logo" />
            </div> */}
            <div className="mobile-marketing-sec2">
              <img src={sale4} alt="logo" />
            </div>
            <div className="mobile-marketing-sec3">
              <img src={sale3} alt="logo" />
            </div>
            <div className="mobile-marketing-sec4">
              <img src={sale1} alt="logo" />
            </div>
          </div>
        </div>
        <div className="mobile-other-members">
          <div className="mobile-other-member1">
            <AboutLogoCard
              LogoAboutSec={shariah}
              aboutLogoTitle={t("advisoryTeamTitle")}
            />
          </div>
          <div className="mobile-other-member2">
            <AboutLogoCard
              LogoAboutSec={gLogo}
              aboutLogoTitle={t("ventureCapitalTitle")}
            />
          </div>
          <div className="mobile-other-member3">
            <AboutLogoCard
              LogoAboutSec={gLogo}
              aboutLogoTitle={t("stockManagerTitle")}
            />
          </div>
        </div>
        <div className="mobile-crypto-manager">
          <div className="mobile-crypto-manager-sec text-center">
            <h4>{t("cryptoManagerTitle")}</h4>
            <AboutLogoCard LogoAboutSec={d1} />
          </div>
        </div>
        <div className="mobile-estate-team">
          <h4>{t("realEstateTeamTitle")}</h4>
          <div className="mobile-estate-members">
            {/* <AboutLogoCard LogoAboutSec={estate1} /> */}
            <AboutLogoCard LogoAboutSec={estate2} />
          </div>
        </div>
        <div className="mobile-crypto-manager">
          <div className="mobile-crypto-manager-sec text-center">
            <h4>Mascotte</h4>
            <AboutLogoCard LogoAboutSec={catLogo} />
          </div>
        </div>
        <div className="mobile-tech-team">
          <h4>{t("techTeamTitle")}</h4>
          <div className="mobile-tech-members">
            <AboutLogoCard LogoAboutSec={tech1} />
            <AboutLogoCard LogoAboutSec={tech2} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTeam;
