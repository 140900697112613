import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../components/MainPage/HomePage";
import Work from "../components/Work/Work";
import About from "../components/About/About";
import Invest from "../components/Invest/Invest";
import SignUp from "../components/SignUP/SignUp";
import Login1 from "../components/Login1/Login1";
import Login from "../components/Login/Login";
import Contact from "../components/Contact/Contact";
import HowItWorks from "../components/How it Works/HowItWorks";
import AfmMain from "../components/Afm/AfmMain";
import BasicModal from "../components/Popover/ModalBoxShow";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Benefits from "../components/Benefits/Benefits";
import Shariah from "../components/Shariah/Sharaih";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import TradingGraphs from "../components/TradingGraphs/TradingGraphs";
import InvestSlider from "../components/TradingGraphs/InvestSlider";
import FundingCard from "../components/Invest/FundingCard";
import GrowthPdf from "../components/Subcomponents/GrowthPdf/GrowthPdf";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermUse from "../components/TermUse/TermUse";
import LatestNews from "../components/LatestNews/LatestNews";
import BlogState from "../context/blogs/BlogsState";
import Frame1 from "../components/Frame1/Frame1";
import Frame2 from "../components/Frame2/Frame2";
import Frame3 from "../components/Frame3/Frame3";
import Frame4 from "../components/Frame4/Frame4";
import LatestNewsOne from "../components/Subcomponents/LatestNewsDetail/LatestNewsOne";
import LatestNewsTwo from "../components/Subcomponents/LatestNewsDetail/LatestNewsTwo";
import LatestNewsThree from "../components/Subcomponents/LatestNewsDetail/LatestNewsThree";
import LatestNewsFour from "../components/Subcomponents/LatestNewsDetail/LatestNewsFour";
import LatestNewsFive from "../components/Subcomponents/LatestNewsDetail/LatestNewsFive";
import LatestNewsSix from "../components/Subcomponents/LatestNewsDetail/LatestNewsSix";
import LatestNewsSeven from "../components/Subcomponents/LatestNewsDetail/LatestNewsSeven";

const MainRoute = () => {
  // const [isVisible, setIsVisible] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsVisible(true);
  //   }, 8200);
  // }, []);

  return (
    <>
      <BlogState>
        <Router>
          <ScrollToTop />
          <Routes>
            {/* <Route
              path={"/"}
              index
              element={isVisible ? <HomePage /> : <Loading />}
            /> */}
            <Route path="/" element={<HomePage />} />
            <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/directfund" element={<Invest />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/login' element={<Login1 />} />
            <Route path='/logiiii' element={<Login />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/howitworks' element={<HowItWorks />} />
            <Route path='/afmregisteration' element={<AfmMain />} />
            <Route path='/popshow' element={<BasicModal />} />
            <Route path='/benefit' element={<Benefits />} />
            <Route path="/principles" element={<Shariah />} />
            <Route path="foundation" element={<ComingSoon />} />
            <Route path="/graph" element={<TradingGraphs />}></Route>
            <Route path="/graph1" element={<InvestSlider />}></Route>
            <Route path="/funding" element={<FundingCard />}></Route>
            <Route path="/satisfactionpdf" element={<GrowthPdf />}></Route>
            <Route path="/policy" element={<PrivacyPolicy />}></Route>
            <Route path="/termuse" element={<TermUse />}></Route>
            <Route path="/latestnews" element={<LatestNews />}></Route>
            <Route path="/latest1" element={<LatestNewsOne />} />
            <Route path="/latest2" element={<LatestNewsTwo />} />
            <Route path="/latest3" element={<LatestNewsThree />} />
            <Route path="/latest4" element={<LatestNewsFour />} />
            <Route path="/latest5" element={<LatestNewsFive />} />
            <Route path="/latest6" element={<LatestNewsSix />} />
            <Route path="/latest7" element={<LatestNewsSeven />}></Route>
            <Route path="/funds" element={<Frame1 />}></Route>
            <Route path="/residential" element={<Frame2 />}></Route>
            <Route path="/development" element={<Frame3 />}></Route>
            <Route path="/cryptoFund" element={<Frame4 />}></Route>
          </Routes>
        </Router>
      </BlogState>
    </>
  );
};
export default MainRoute;